import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { sendPayinProof } from '../store/transaction/action';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Cancel = () => {
    const transaction = useSelector((state) => state.transaction);

    return (
        <>

<div className="pb-28 pt-12 bg-[#0C2E74] payment__wrapper">
        <div className="container mx-auto">
          <div className="mx-auto w-full max-w-[576px] sm:px-4">
            <h1 className="text-center text-white text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold mb-4">Transaction failed!</h1> 
          </div>
        </div>
      </div>

      <div className=" -mt-20 relative z-10 pb-20">
        <div className="container mx-auto">
          <div className="mx-auto w-full max-w-[576px] sm:px-4">
            <div className="bg-white p-4 md:px-8 sm:py-7 rounded-xl shadow-[0px_0px_15px_0px_rgba(0,0,0,0.05)]">
              <img 
                  src={'/assets/images/fail-icon.png'} 
                  alt={'Fail'} 
                  className='md:w-[150] w-[100px] mx-auto' 
              />
              <p className="text-center font-medium text-base md:text-lg lg:text-[22px] text-[#5B5D66] mt-5">{transaction.transaction.status.toUpperCase()}</p>
              <p className="text-center font-bold text-base md:text-lg text-[#5B5D66] mt-4">Please try generating a new transaction on the merchant site and attempt again.</p>
              <div className="border-t-2 border-dashed border-[#C9CBCD] my-5 md:my-9"></div>
              <p className="text-center font-medium text-base md:text-lg text-[#5B5D66] mt-4">Transaction : {transaction.transaction.id}</p>
              <p className="text-center text-sm md:text-base text-[#A7A5A5] mt-2">{new Date(transaction.transaction.added_on).toString()}</p>
              
            </div> 
          </div>
        </div>
      </div>
        </>
    )
}

export default Cancel