import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Tabs, Progress } from 'antd';
import { BankBuildingIcon, CopyIcon, WalletIconTab } from './Icons'
import Upi from './Upi';
import IntentUpi from './IntentUpi';
import Bank from './Bank';
import toast from 'react-simple-toasts';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Landing = () => {
    const [circle, setCircle] = useState('100');
    const navigate = useNavigate();
    const transaction = useSelector((state) => state.transaction);
    function changeRoute(route) {
        navigate(`${route}?tr=${transaction.transaction.id}`);
    }
    const [timeLeft, setTimeLeft] = useState(900); // Set initial time in seconds (e.g., 600 seconds = 10 minutes)

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime > 0 ? prevTime - 1 : 0);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const items = [];

    if (transaction.payinOptions.hasOwnProperty("ICEGAME")) {
        items.push({
            key: '1',
            label: <div className='flex gap-3 w-full'>
                <div className="w-10 hidden sm:block">
                    <WalletIconTab />
                </div>
                <div className="flex-1 leading-[1.1]">
                    <span className="text-base md:text-lg lg:text-xl font-bold block">UPI</span>
                    <span className='text-xs md:text-sm font-medium text-[#8D8E91]'>Scan QR or Wallet</span>
                </div>
            </div>,
            children: <IntentUpi />,
        })
    }

    if (transaction.payinOptions.hasOwnProperty("UPI")) {
        items.push({
            key: '2',
            label: <div className='flex gap-3 w-full'>
                <div className="w-10 hidden sm:block">
                    <WalletIconTab />
                </div>
                <div className="flex-1 leading-[1.1]">
                    <span className="text-base md:text-lg lg:text-xl font-bold block">UPI</span>
                    <span className='text-xs md:text-sm font-medium text-[#8D8E91]'>Copy UPI or Scan QR</span>
                </div>
            </div>,
            children: <Upi />,
        })
    }

    if (transaction.payinOptions.hasOwnProperty("BANK")) {
        items.push({
            key: '3',
            label: <div className='flex gap-3 w-full'>
                <div className="w-10 hidden sm:block">
                    <BankBuildingIcon />
                </div>
                <div className="flex-1 leading-[1.1]">
                    <span className="text-base md:text-lg lg:text-xl font-bold block">Bank Transfer</span>
                    <span className='text-xs md:text-sm font-medium text-[#8D8E91]'>IMPS and RTGS only</span>
                </div>
            </div>,
            children: <Bank />,
        })
    }

    // response menu state changing events
    useEffect(() => {
        const width = window.innerWidth;
        if (width < 1024) {
            setCircle(70);
        } else {
            setCircle(100);
        }
        window.addEventListener('resize', () => {
            const width = window.innerWidth;
            if (width < 1024) {
                setCircle(70);
            } else {
                setCircle(100);
            }
        })
        return () => {
            window.removeEventListener('resize', () => {
                const width = window.innerWidth;
                if (width < 1024) {
                    setCircle(70);
                } else {
                    setCircle(100);
                }
            })
        }
    }, []);


    return (<>

        <div className="pb-28 pt-8 bg-[#0C2E74] payment__wrapper">
            <div className="container mx-auto">
                <div className="mx-auto w-full max-w-[576px] sm:px-4">
                    <h1 className="text-center text-[#C4D8FF] text-lg md:text-xl font-medium mb-4">Amount</h1>
                    <div className="flex justify-center gap-4">
                        <div className="text-3xl md:text-4xl lg:text-5xl font-bold text-white">{transaction.amountMapper}</div>
                        <CopyToClipboard
                            text={transaction.amountMapper}
                            onCopy={() => { toast('Amount Copied!') }}
                        >
                            <button className="w-12 h-12 rounded-full bg-[#71A0FF] flex items-center justify-center hover:bg-[#71A0FF]/75">
                                <CopyIcon />
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div className="flex sm:gap-0 justify-between md:mt-10 mt-4 text-center
                     sm:text-left">
                        <div className=" flex-1 sm:pr-5 text-left">
                            <p className="text-base md:text-lg font-bold text-white mb-0 sm:mb-2">Verification In-Progress</p>
                            <p className='text-sm md:text-[15px] text-white/70'>Please pay the exact amount, otherwise, the system will ignore the payment</p>
                        </div>
                        <div className="w-20 sm:w-28 flex justify-center sm:justify-end">
                            <Progress
                                type="circle"
                                percent={(timeLeft / 1000) * 100}
                                size={circle}
                                format={() => <span className='text-white text-base md:text-lg lg:text-xl font-bold'>{formatTime(timeLeft)}</span>}
                                strokeWidth={10}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div className=" -mt-20 relative z-10 pb-20">
            <div className="container mx-auto">
                <div className="mx-auto w-full max-w-[576px] sm:px-4">
                    <div className="bg-white p-4 md:px-5 rounded-xl shadow-[0px_0px_15px_0px_rgba(0,0,0,0.05)]">

                        <Tabs defaultActiveKey="1" items={items} className='payment__tab' />
                    </div>
                    <p className="text-center mt-6 text-[#232e51] font-medium">100% Secure Payments</p>
                </div>
            </div>
        </div>
    </>)

}

export default Landing