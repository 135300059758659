import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import QRCode from 'qrcode.react';
import { ColorRing } from 'react-loader-spinner';
import { sendPayinProof } from '../store/transaction/action';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-simple-toasts';
import {isUTR} from '../helper/common_helper'
import { CopyIcon } from './Icons'

const IntentUpi = () => {
    const dispatch = useDispatch()
    const transaction = useSelector((state) => state.transaction);
    const [utr, setUtr] = useState("");
    const [loading, setLoading] = useState({ utr: false });

    // useEffect(()=>{
    //     if (transaction.loading === "stop"){
    //         setLoading((preState) => ({ ...preState, "utr": false }));
    //     }
    // },[transaction.loading]);

    function handleSubmit() {
        if (!utr) {
            return;
        }
        if(!isUTR(utr)){
            toast("Please enter valid 12 digit UTR number")
            return
        }
        setLoading((preState) => ({ ...preState, "utr": true }));
        const data = {
            "transaction_id": transaction.transaction.id,
            "payment_proof": utr
        }
        dispatch(sendPayinProof(data));
    }

    const [timeLeft, setTimeLeft] = useState(900); // Set initial time in seconds (e.g., 600 seconds = 10 minutes)

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime > 0 ? prevTime - 1 : 0);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return <>
    <div className='p-2 md:p-4 pb-0'>
        <p className="text-center font-medium text-[#5B5D66]">Pay With UPI QR</p>
        <div className="mt-6 text-center">
            {/* <img 
                src={'/assets/images/qr.png'} 
                alt={'QR'} 
                className='md:w-[210px] w-[150px] mx-auto' 
            /> */}
            <QRCode className='md:w-[210px] w-[150px] mx-auto' value={`${transaction.payinOptions.ICEGAME.QR_STRING}`}
                            size={256}
                            fgColor="#1C50D0"
                            level="H" // Error correction level ('L', 'M', 'Q', 'H')
                        // includeMargin={true} // Include margin around the QR code
                        />
        </div>

        {/* <div className="p-3 rounded-xl flex items-center justify-between bg-[#E3EBF5] mt-6 md:mt-8">
            <p className='flex-1 text-[#232e51] text-base md:text-lg font-medium mb-0'>{transaction.payinOptions.UPI.UPI_ID}</p>
            <CopyToClipboard
                                text={transaction.payinOptions.UPI.UPI_ID}
                                onCopy={() => { toast('UPI Copied!')}}
                            >
            <button className="w-10 h-10 lg:w-12 md:h-12 rounded-full bg-[#71A0FF] flex items-center justify-center hover:bg-[#71A0FF]/75">
                <CopyIcon />
            </button>
            </CopyToClipboard>
        </div> */}

        <p className="text-center font-medium text-[#5B5D66] mt-10">Scan the QR with any UPI app or pay using any wallet</p>
        <a href={transaction.payinOptions.ICEGAME.QR_STRING}><img 
            src={'/assets/images/upi-payments.png'} 
            alt={'QR'} 
            className=' mx-auto' 
        />
        </a>
    </div>
    </>
   
}

export default IntentUpi