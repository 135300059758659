import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { sendPayinProof } from '../store/transaction/action';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ColorRing } from 'react-loader-spinner';
import toast from 'react-simple-toasts';
import {isUTR} from '../helper/common_helper'
import { CopyIcon } from './Icons';

const Bank = () => {
    const dispatch = useDispatch()
    const transaction = useSelector((state) => state.transaction);
    const [utr, setUtr] = useState("");
    const [loading, setLoading] = useState({ utr: false });

    // useEffect(()=>{
    //     if (transaction.loading === "stop"){
    //         setLoading((preState) => ({ ...preState, "utr": false }));
    //     }
    // },[transaction.loading]);

    function handleSubmit() {
        if (!utr) {
            return;
        }
        if(!isUTR(utr)){
            toast("Please enter valid 12 digit UTR number")
            return
        }
        setLoading((preState) => ({ ...preState, "utr": true }));
        const data = {
            "transaction_id": transaction.transaction.id,
            "payment_proof": utr
        }
        dispatch(sendPayinProof(data));
    }

    return <>
     <div className='sm:p-2 md:p-4 pb-0'>
        <p className="text-center font-medium text-base md:text-lg lg:text-xl text-[#232E51]">Please transfer same amount on this account. IMPS and RTGS only</p>

        <ul className="flex flex-col gap-3 sm:gap-4 mt-10 p-0">
            <li className='border-b-2 border-dashed border-[#E6E2E2] pb-3 flex items-center'>
                <div className="flex-1"> 
                    <span className="text-sm  md:text-base font-medium text-[#5B5D66] block">Receiver Name</span>
                    <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-bold text-primary block">{transaction.payinOptions.BANK.HOLDER_NAME}</span>
                </div>
                <CopyToClipboard
                                text={transaction.payinOptions.BANK.HOLDER_NAME}
                                onCopy={() => { toast('Name Copied!')}}
                            >
                <button className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-[#71A0FF] flex items-center justify-center hover:bg-[#71A0FF]/75">
                    <CopyIcon />
                </button>
                </CopyToClipboard>
            </li>
            <li className='border-b-2 border-dashed border-[#E6E2E2] pb-3 flex items-center'>
                <div className="flex-1"> 
                    <span className="text-sm  md:text-base font-medium text-[#5B5D66] block">Bank Name</span>
                    <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-bold text-primary block">{transaction.payinOptions.BANK.NAME}</span>
                </div>
                <CopyToClipboard
                                text={transaction.payinOptions.BANK.NAME}
                                onCopy={() => { toast('Bank Copied!')}}
                            >
                <button className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-[#71A0FF] flex items-center justify-center hover:bg-[#71A0FF]/75">
                    <CopyIcon />
                </button>
                </CopyToClipboard>
            </li>
            <li className='border-b-2 border-dashed border-[#E6E2E2] pb-3 flex items-center'>
                <div className="flex-1"> 
                    <span className="text-sm  md:text-base font-medium text-[#5B5D66] block">Account No</span>
                    <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-bold text-primary block">{transaction.payinOptions.BANK.ACCOUNT_NO}</span>
                </div>
                <CopyToClipboard
                                text={transaction.payinOptions.BANK.ACCOUNT_NO}
                                onCopy={() => { toast('Account no Copied!')}}
                            >
                <button className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-[#71A0FF] flex items-center justify-center hover:bg-[#71A0FF]/75">
                    <CopyIcon />
                </button>
                </CopyToClipboard>
            </li>
            <li className='border-b-2 border-dashed border-[#E6E2E2] pb-3 flex items-center'>
                <div className="flex-1"> 
                    <span className="text-sm  md:text-base font-medium text-[#5B5D66] block">IFSC Code</span>
                    <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-bold text-primary block">{transaction.payinOptions.BANK.IFSC_CODE}</span>
                </div>
                <CopyToClipboard
                                text={transaction.payinOptions.BANK.IFSC_CODE}
                                onCopy={() => { toast('IFSC no Copied!')}}
                            >
                <button className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-[#71A0FF] flex items-center justify-center hover:bg-[#71A0FF]/75">
                    <CopyIcon />
                </button>
                </CopyToClipboard>
            </li> 
        </ul>
    </div>
    </>

}

export default Bank

