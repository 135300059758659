import * as React from "react"


export const DashboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M12.604 15h-2.5a.625.625 0 0 0 0 1.25h2.5a.625.625 0 0 0 0-1.25ZM11.354 7.305c-.354 1.676-.623 3.058-.625 3.32a.626.626 0 0 0 1.25 0c-.002-.262-.27-1.644-.625-3.32Z"
    />
    <path
      fill="#fff"
      d="M11.354 0C5.495 0 .688 4.766.688 10.625c0 3.86 2.14 7.422 5.518 9.296a.62.62 0 0 0 .303.079h9.69c.106 0 .21-.027.303-.079 3.379-1.874 5.519-5.436 5.519-9.296C22.02 4.766 17.213 0 11.354 0Zm1.25 17.5h-2.5a1.877 1.877 0 0 1-1.875-1.875c0-1.034.841-1.875 1.875-1.875h2.5c1.034 0 1.875.841 1.875 1.875a1.877 1.877 0 0 1-1.875 1.875Zm6.25-6.25h-2.5a.625.625 0 1 1 0-1.25h1.847c-.13-1.427-.712-2.715-1.581-3.757l-1.288 1.288a.625.625 0 1 1-.884-.884l1.288-1.287a6.814 6.814 0 0 0-3.757-1.55v.496c.242 1.077 1.25 5.612 1.25 6.319a1.877 1.877 0 0 1-1.875 1.875 1.877 1.877 0 0 1-1.875-1.875c0-.707 1.008-5.242 1.25-6.32v-.523a6.842 6.842 0 0 0-3.77 1.563L8.26 6.647a.625.625 0 1 1-.883.884L6.074 6.23A6.842 6.842 0 0 0 4.511 10h1.843a.625.625 0 1 1 0 1.25h-2.5a.625.625 0 0 1-.625-.625c0-4.468 3.636-8.125 8.125-8.125 4.468 0 8.125 3.636 8.125 8.125 0 .345-.28.625-.625.625Z"
    />
  </svg>
) 


export const PayIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="#5E74B7"
        d="M10.95.193c-1.719 0-3.118 1.44-3.118 3.16 0 1.72 1.4 3.119 3.119 3.119 1.719 0 3.118-1.4 3.118-3.119 0-1.72-1.399-3.16-3.118-3.16ZM14.905 11.477a2.497 2.497 0 0 0 2.494-2.495 2.497 2.497 0 0 0-2.495-2.495 2.497 2.497 0 0 0-2.494 2.495 2.497 2.497 0 0 0 2.495 2.495ZM.826 18.946h.624c.69 0 1.247-.559 1.247-1.247v-7.485c0-.689-.558-1.247-1.247-1.247H.826a.624.624 0 0 0-.623.623v8.732c0 .345.279.624.623.624ZM4.054 10.302v7.484c0 .126-.019.246-.037.367l3.64 1.8c.43.214.913.328 1.394.328h5.634c.463 0 .916-.128 1.311-.372l5.107-3.61a1.25 1.25 0 0 0 .25-1.748c-.376-.5-1.101-.715-1.747-.249l-3.84 2.166a.623.623 0 0 1-.29.07H8.338a.623.623 0 1 1 0-1.247h5.738a1.247 1.247 0 0 0 0-2.494h-2.93L8.19 10.826a3.104 3.104 0 0 0-1.73-.524H4.054Z"
      />
    </svg>
)

export const PayoutIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={17}
      fill="none"
      {...props}
    >
      <path
        fill="#5E74B7"
        d="M5.967 14.406v1.687a.844.844 0 0 0 .843.844h1.688a2.531 2.531 0 0 0-2.531-2.531ZM16.935 16.937h1.687a.844.844 0 0 0 .844-.844v-1.687a2.531 2.531 0 0 0-2.531 2.53ZM12.716 10.187a2.531 2.531 0 1 0 0-5.062 2.531 2.531 0 0 0 0 5.062Z"
      />
      <path
        fill="#5E74B7"
        d="M16.09 5.125c.553.728.85 1.618.845 2.531a4.219 4.219 0 0 1-8.437 0 4.151 4.151 0 0 1 .843-2.53H5.967v7.592a4.219 4.219 0 0 1 4.218 4.219h5.062a4.219 4.219 0 0 1 4.219-4.219V5.125H16.09Z"
      />
      <path
        fill="#5E74B7"
        d="M23.684.063H1.748a.844.844 0 0 0-.843.844v5.062a.844.844 0 0 0 .843.844H4.28V4.282a.844.844 0 0 1 .844-.844h15.186a.844.844 0 0 1 .844.844v2.53h2.531a.844.844 0 0 0 .844-.843V.907a.844.844 0 0 0-.844-.844Z"
      />
    </svg>
)

export const WalletIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="#5E74B7"
        d="M8.518 3.066c0-1.077.876-1.954 1.953-1.954h.676l-.204-.566a.781.781 0 0 0-1.002-.47l-6.83 2.486a.781.781 0 0 0-.467 1.003l.361.985h5.513V3.066Z"
      />
      <path
        fill="#5E74B7"
        d="M10.471 2.284a.781.781 0 0 0-.78.782V4.55h6.835V3.066a.781.781 0 0 0-.781-.782H10.47ZM18.596 15.82h-2.734a2.933 2.933 0 0 1-2.93-2.93 2.933 2.933 0 0 1 2.93-2.93h2.734V7.284c0-.863-.7-1.562-1.562-1.562H1.917c-.863 0-1.563.7-1.563 1.562v11.182c0 .863.7 1.563 1.563 1.563h15.117c.863 0 1.562-.7 1.562-1.563V15.82Z"
      />
      <path
        fill="#5E74B7"
        d="M19.768 11.132h-3.906a1.76 1.76 0 0 0-1.758 1.758c0 .969.789 1.758 1.758 1.758h3.906a.587.587 0 0 0 .586-.586v-2.344a.587.587 0 0 0-.586-.586Zm-3.906 2.344a.586.586 0 1 1 0-1.172.586.586 0 0 1 0 1.172Z"
      />
    </svg>
)

export const GatewayIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="#5E74B7"
        d="M11.951 15.806a2.423 2.423 0 1 0 0-4.846 2.423 2.423 0 0 0 0 4.846Z"
      />
      <path
        fill="#5E74B7"
        d="M19.367 6.737H4.535a3.988 3.988 0 0 0-3.987 3.986v5.319c0 2.2 1.786 3.987 3.987 3.987h14.832c2.2 0 3.987-1.787 3.987-3.987v-5.319a3.985 3.985 0 0 0-3.986-3.986ZM3.875 12.202a.977.977 0 0 1-.98-.98c0-.543.437-.98.98-.98a.98.98 0 1 1 0 1.96Zm8.076 4.717a3.537 3.537 0 1 1 3.537-3.536 3.545 3.545 0 0 1-3.537 3.537Zm8.076-4.717a.977.977 0 0 1-.98-.98c0-.543.436-.98.98-.98.543 0 .98.437.98.98 0 .54-.441.98-.98.98ZM7.973 2.514a.594.594 0 0 0 .419.174.573.573 0 0 0 .405-.165 4.487 4.487 0 0 1 6.303 0c.107.107.254.17.406.165a.594.594 0 0 0 .418-.174l.018-.013a.593.593 0 0 0-.004-.842 5.69 5.69 0 0 0-7.974 0 .594.594 0 0 0-.004.842l.013.013Z"
      />
      <path
        fill="#5E74B7"
        d="M9.951 3.641a.592.592 0 0 0-.018.838l.01.008.012.014a.596.596 0 0 0 .837-.004c.651-.62 1.667-.62 2.317 0a.598.598 0 0 0 .837.004l.014-.014a.592.592 0 0 0 0-.837l-.009-.009a2.878 2.878 0 0 0-4 0Z"
      />
    </svg>
)

export const UsersIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="#5E74B7"
        d="M14.696 3.461a4.319 4.319 0 0 1 2.01 3.209c.448.209.945.33 1.472.33a3.485 3.485 0 1 0-3.482-3.539Zm-2.284 7.137a3.486 3.486 0 1 0 0-6.971 3.486 3.486 0 0 0 0 6.97Zm1.479.237h-2.958A4.468 4.468 0 0 0 6.47 15.3v3.617l.01.056.249.078c2.348.734 4.388.979 6.068.979 3.28 0 5.18-.936 5.298-.995l.233-.118h.025V15.3a4.467 4.467 0 0 0-4.462-4.463Zm5.766-3.598h-2.934a4.296 4.296 0 0 1-1.326 2.992 5.303 5.303 0 0 1 3.788 5.075v1.115c2.897-.107 4.567-.928 4.677-.983l.233-.118h.025V11.7a4.468 4.468 0 0 0-4.463-4.463ZM6.297 7c.681 0 1.316-.199 1.853-.538a4.314 4.314 0 0 1 1.621-2.751c.004-.065.01-.13.01-.196A3.485 3.485 0 1 0 6.296 7Zm3.13 3.23a4.298 4.298 0 0 1-1.325-2.976c-.11-.008-.217-.017-.328-.017H4.817A4.468 4.468 0 0 0 .354 11.7v3.617l.01.056.248.079c1.884.588 3.566.859 5.026.944v-1.092c0-2.396 1.6-4.424 3.789-5.075Z"
      />
    </svg>
)

export const CopyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#114ECC"
      d="M12.928 3.448H2.68A2.196 2.196 0 0 0 .484 5.644v14.64c0 1.213.983 2.196 2.196 2.196h10.248a2.196 2.196 0 0 0 2.196-2.196V5.644a2.196 2.196 0 0 0-2.196-2.196Z"
    />
    <path
      fill="#114ECC"
      d="M19.516 2.716v14.64a2.203 2.203 0 0 1-2.196 2.196h-.732V5.644a3.66 3.66 0 0 0-3.66-3.66h-7.92A2.196 2.196 0 0 1 7.071.52H17.32a2.203 2.203 0 0 1 2.196 2.196Z"
    />
  </svg>
)

export const WalletIconTab = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={41}
    fill="none"
    {...props}
  >
    <path
      fill="#3A7AFE"
      d="M37.272 33.227H30.91c-4.01 0-7.273-3.262-7.273-7.272s3.263-7.273 7.273-7.273h6.363c.503 0 .91-.406.91-.909v-2.727c0-1.906-1.48-3.457-3.348-3.607l-5.22-9.12a3.592 3.592 0 0 0-2.2-1.696 3.573 3.573 0 0 0-2.737.367L6.78 11.41H3.636A3.64 3.64 0 0 0 0 15.046v21.818A3.64 3.64 0 0 0 3.636 40.5h30.909a3.64 3.64 0 0 0 3.636-3.636v-2.728a.909.909 0 0 0-.909-.909ZM30.741 7.95l1.98 3.46h-7.923l5.943-3.46Zm-20.348 3.46 15.2-8.849c.41-.24.89-.304 1.35-.182.464.124.851.424 1.092.844l.002.004-14.055 8.182h-3.589Z"
    />
    <path
      fill="#3A7AFE"
      d="M37.272 20.5h-6.364a5.46 5.46 0 0 0-5.454 5.455 5.46 5.46 0 0 0 5.454 5.454h6.364a2.73 2.73 0 0 0 2.727-2.727v-5.454a2.73 2.73 0 0 0-2.727-2.728Zm-6.364 7.273a1.82 1.82 0 0 1-1.818-1.818 1.82 1.82 0 0 1 1.818-1.818 1.82 1.82 0 0 1 1.818 1.818 1.82 1.82 0 0 1-1.818 1.818Z"
    />
  </svg>
)

export const BankBuildingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={41}
    fill="none"
    {...props}
  >
    <path
      fill="#0F2655"
      d="M38.53 36.863H2.167a1.819 1.819 0 1 0 0 3.637H38.53a1.818 1.818 0 1 0 0-3.637ZM4.438 32.135a1.818 1.818 0 1 0 0 3.638h31.82a1.819 1.819 0 0 0 0-3.638h-.455V15.77h.454a.908.908 0 1 0 0-1.817H4.438a.908.908 0 1 0 0 1.817h.455v16.364h-.455ZM32.166 15.77v16.364h-5.454V15.77h5.454Zm-9.092 0v16.364H17.62V15.77h5.454Zm-14.545 0h5.454v16.364H8.53V15.77ZM2.166 12.318h36.382a1.818 1.818 0 0 0 .59-3.54L21.095.66a1.822 1.822 0 0 0-1.491 0L1.42 8.842a1.818 1.818 0 0 0 .746 3.476Z"
    />
  </svg>
)