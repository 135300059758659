import React from 'react';
import { Rings } from 'react-loader-spinner';

const Loader = () => {
    return (
        <section className="loading-wrapper flex justify-center items-start min-h-screen">
            <div className="d-flex flex-column align-items-center justify-content-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <Rings
                    visible={true}
                    height="100"
                    width="100"
                    color="#1C50D0"
                    ariaLabel="rings-loading"
                />
                <p className="fs-20 mt-3 fs-4 mb-1"><strong>Please Wait</strong></p>
                <p className='fs-6'>Working on your request</p>
            </div>
        </section>
    );
};

export default Loader;
